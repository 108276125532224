import { render, staticRenderFns } from "./ManageLeague.vue?vue&type=template&id=65ef6cfa&scoped=true&"
import script from "./ManageLeague.vue?vue&type=script&lang=js&"
export * from "./ManageLeague.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65ef6cfa",
  null
  
)

export default component.exports